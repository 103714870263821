import { Box, Button, Grommet, TextInput } from 'grommet'
import React, { Component } from 'react'

import Img from 'gatsby-image'
import {
  StyledSpan,
} from './styles'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import media from 'styled-media-query'
import styled from 'styled-components'

const StyledButton = styled(Button)`
  font-size: 14px;
  font-weight: normal;
  padding: auto 25px;
  border-radius: 4px 4px 4px 4px;
  height: 48px;
  transition: all 500ms ease;
  text-transform: uppercase;
  margin-top: 10px;

  color: ${props => props.theme.colors.buttonLight};
  box-shadow: inset 0 0 0 1px
    ${props =>
      props.light
        ? props.theme.colors.buttonLight
        : props.theme.colors.buttonDark};
  background: ${props =>
    props.light ? 'transparent' : props.theme.colors.buttonDark};
  border: none;

  &:hover {
    background: ${props =>
      props.light ? 'transparent' : props.theme.colors.buttonDarkHover};
    box-shadow: 0 0 1px 0
      ${props =>
        props.light
          ? props.theme.colors.buttonLightHover
          : props.theme.colors.buttonDarkHover};
    color: ${props =>
      props.light
        ? props.theme.colors.buttonLightHover
        : props.theme.colors.buttonLight};
    background-origin: border-box;
  }

  ${media.greaterThan('small')`
  margin-top: 0px;
`}
`

const StyledHeader = styled.h3`
  font-weight: 300;
`

const ButtonWrapper = styled(Box)`
  margin: 1em 0;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    'input'
    'button';
  ${media.greaterThan('medium')`
    grid-template-rows: 100%;
    grid-template-columns: auto auto;
    grid-column-gap: 16px;
    grid-template-areas: 
      "input button";
  `}
`

const StyledInput = styled(TextInput)`
  grid-area: input;
  border-radius: 4px 4px 4px 4px;
  font-size: 16px;
  font-weight: normal;
  height: 48px;
  max-width: 400px;
  background-color: white;
  color: ${props => props.theme.colors.primaryDark};
`

class InputButton extends Component {
  state = {
    email: '',
    response: {},
  }

  onChange = event => this.setState({ email: event.target.value })

  onClick = async e => {
    e.preventDefault
    const response = await addToMailchimp(this.state.email)
    this.setState({
      response,
    })
  }
  render() {
    return (
      <Box width="100%">
        <StyledHeader>{this.props.title}</StyledHeader>
        <ButtonWrapper>
          <StyledInput
            value={this.state.email}
            onChange={this.onChange}
            focusIndicator={false}
            placeholder="Email Address"
          />
          <StyledButton light label={this.props.label} onClick={this.onClick} />
        </ButtonWrapper>
        {this.state.response && <StyledSpan dangerouslySetInnerHTML={{ __html: this.state.response.msg }} /> }
      </Box>
    )
  }
}

export default InputButton
