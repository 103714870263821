import 'antd/lib/menu/style/index.css'

import React, { Component } from 'react'

import Menu from 'antd/lib/menu'
import PropTypes from 'prop-types'
import { ResponsiveContext } from 'grommet'
import { languages } from '../../i18n/locales'

const SubMenu = Menu.SubMenu
class Language extends Component {
  state = {
    value: '',
    path: '',
  }

  static contextTypes = {
    language: PropTypes.object,
  }

  componentDidMount() {
    if (this.props.post) {
      const { post } = this.props
      const { lang } = post
      this.setState({
        value: lang || 'en',
      })
    } else {
      const { language } = this.context
      this.setState({
        value: language.locale || language.detected,
      })
    }
  }

  handleChange = e => {
    const value = e.key
    let path = ''

    if (value === this.state.value) {
      return
    }

    if (this.props.post) {
      const { post } = this.props
      const { alternate_languages: languages } = post
      const locale = languages.find(language => language.lang === value)
      path = `/${value}/blog/${locale.uid}`
    } else {
      const { language } = this.context
      const { originalPath } = language
      path = `/${value}${originalPath}`
    }

    this.setState({ value, path }, () => {
      localStorage.setItem('language', value)
      window.location.href = path
    })
  }

  render() {
    const currentValue = this.state.value

    if (!currentValue) {
      return null
    }

    return (
      <ResponsiveContext.Consumer>
        {size => (
          <Menu
            onClick={this.handleChange}
            selectedKeys={[this.state.value]}
            mode={
              size === 'large' || size === 'xlarge' ? 'horizontal' : 'inline'
            }
          >
            <SubMenu
              title={
                <span className="submenu-title-wrapper">
                  {
                    languages.find(language => language.value === currentValue)
                      .text
                  }
                </span>
              }
            >
              {languages.map(({ value, text }) => (
                <Menu.Item key={value}>{text}</Menu.Item>
              ))}
            </SubMenu>
          </Menu>
        )}
      </ResponsiveContext.Consumer>
    )
  }
}

Language.propTypes = {
  post: PropTypes.object,
}

export default Language
