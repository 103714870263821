// import {
//   StyledColumnContainer,
//   StyledColumnsContainer,
//   StyledContainer,
//   StyledH3,
//   StyledP,
//   StyledUnderlinedH2,
// } from './styles'
import { injectIntl, intlShape } from 'react-intl'

import { Box } from 'grommet'
import Button from '../reusables/button'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import React from 'react'
import media from 'styled-media-query'
import shortid from 'shortid'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  height: 50vh;
  position: relative;
  background-image: radial-gradient(circle at 0 50%, #efefef, #e6edf8);
`
const StyledImageContainer = styled(Box)`
  max-height: 180px;
  position: absolute;
  top: 60%;
  left: 85%;
  transform: translate(-50%, -50%);
  ${media.lessThan('small')`
    display: none !important;
  `}
`

const ContainerWithButton = styled(Container)`
  height: 100vh;
`
const StyledBox = styled(Box)`
  color: white;
`

const StyledBoxWithButton = styled(StyledBox)``

const StyledH1 = styled.h1`
  margin-bottom: 0px;
  color: ${props => props.theme.global.colors.primaryDark};
`
const StyledH3 = styled.h3`
  color: ${props => props.theme.global.colors.primaryDark};
`

const Banner = ({ data, shade, intl }) => {
  const title = intl.formatMessage({
    id: `banner.title`,
  })
  const hasTitle = title !== 'null'
  const description = intl.formatMessage({
    id: `banner.description`,
  })
  const hasDescription = description !== 'null'
  const buttons = intl.formatMessage({
    id: `banner.buttons.number`,
  })
  const backgroundImage = intl.formatMessage({
    id: `banner.backgroundImage`,
  })
  const backgroundPath = data[`${backgroundImage}`].childImageSharp.fixed

  return buttons > 0 ? (
    <ContainerWithButton justify="center" align="center">
      {/* <Img
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          zIndex: '1',
        }}
        fluid={backgroundPath}
      /> */}
      <StyledBoxWithButton
        style={{
          width: '100%',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: '50',
        }}
        pad={{ vertical: 'small', horizontal: 'xlarge' }}
        align="center"
        justify="center"
        height="100%"
      >
        {hasTitle && <StyledH1>{title}</StyledH1>}
        {hasDescription && <h3>{description}</h3>}
        <Box direction="row-responsive" gap="medium" pad="large">
          {Array.from(Array(Number(buttons)).keys()).map((i, index) => {
            const button = `button${i}`
            const buttonLabel = intl.formatMessage({
              id: `banner.buttons.${button}.label`,
            })
            const buttonLink = intl.formatMessage({
              id: `banner.buttons.${button}.link`,
            })
            return (
              <Button
                key={shortid.generate()}
                label={buttonLabel}
                link={buttonLink}
                light={shade === 'light' || shade === 'grey' ? false : true}
              />
            )
          })}
          {
            <StyledImageContainer>
              <Img alt={buttonLabel} imgStyle={{ objectFit: 'contain' }} fixed={backgroundPath} />
            </StyledImageContainer>
          }
        </Box>
      </StyledBoxWithButton>
    </ContainerWithButton>
  ) : (
    <Container>
      {/* <Img
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        fluid={backgroundPath}
      /> */}
      <StyledBox
        style={{
          width: '100%',
          position: 'absolute',
          top: '60%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        pad="xlarge"
        align="start"
        justify="center"
        height="100%"
      >
        {hasTitle && <StyledH1>{title}</StyledH1>}
        {hasDescription && <StyledH3>{description}</StyledH3>}
      </StyledBox>
      <StyledImageContainer>
        <Img alt={backgroundPath} imgStyle={{ objectFit: 'contain' }} fixed={backgroundPath} />
      </StyledImageContainer>
    </Container>
  )
}

Banner.propTypes = {
  data: PropTypes.object,
  shade: PropTypes.string,
  index: PropTypes.number,
  intl: intlShape.isRequired,
}

export default injectIntl(Banner)
