import 'react-sticky-header/styles.css'
import 'rc-menu/assets/index.css'

import Menu, { Divider, Item as MenuItem, SubMenu } from 'rc-menu'

import { Box } from 'grommet'
import Img from 'gatsby-image'
import Language from './language'
import PropTypes from 'prop-types'
import React from 'react'
import StickyHeader from 'react-sticky-header'
import animate from 'css-animation'
import shortid from 'shortid'
import styled from 'styled-components'

const StyledMenu = styled(Box)`
  background-color: ${props => props.theme.colors.primaryWhite};
  align-self: center;
  .rc-menu-horizontal {
    margin-top: 0;
    background-color: transparent;
    border-bottom: none;

    .rc-menu-submenu,
    .rc-menu-submenu-active {
      cursor: pointer;
      background-color: transparent;
      padding: 12px 0;
      border-bottom: none;

      .rc-menu-submenu-title {
        background-color: transparent;
      }
    }

    .rc-menu-submenu-active {
      // border-bottom: 2px solid ${props => props.theme.colors.primaryDark};
      border-bottom: none;
      color: ${props => props.theme.colors.primaryDark};
    }
  }
`

const StyledSubMenu = styled(SubMenu)`
  font-size: 14px;
  color: ${props => props.theme.colors.menuItem};
  font-family: Montserrat;
  cursor: pointer;
`

const StyledMenuItem = styled(MenuItem)`
  font-size: 14px;
  color: ${props => props.theme.colors.menuItem};
  font-family: Montserrat;
  cursor: pointer;
`

const AppBar = styled(Box)`
  background-color: ${props => props.theme.colors.primaryWhite};
  height: 80px;
  border-bottom: 1px solid ${props => props.theme.colors.greyBorder};
`
const StyledLogoContainer = styled.a`
  width: 169px;
  height: 35px;
`

const animation = {
  enter(node, done) {
    let height
    return animate(node, 'rc-menu-collapse', {
      start() {
        height = node.offsetHeight
        node.style.height = 0
      },
      active() {
        node.style.height = `${height}px`
      },
      end() {
        node.style.height = ''
        done()
      },
    })
  },

  appear() {
    return this.enter.apply(this, arguments)
  },

  leave(node, done) {
    return animate(node, 'rc-menu-collapse', {
      start() {
        node.style.height = `${node.offsetHeight}px`
      },
      active() {
        node.style.height = 0
      },
      end() {
        node.style.height = ''
        done()
      },
    })
  },
}

class AppBarMenu extends React.Component {
  render() {
    const { post, menuItems, data, current, handleClick } = this.props
    return (
      <StickyHeader
        header={
          <AppBar
            tag="header"
            direction="row-responsive"
            align="center"
            justify="between"
            background="light-1"
            elevation=""
            pad={{ horizontal: 'xlarge', vertical: 'medium' }}
            style={{
              zIndex: '100',
            }}
          >
            <StyledLogoContainer href="/">
              <Img
                alt="cybermiles logo"
                fixed={data.cybermilesLogo.childImageSharp.fixed}
              />
            </StyledLogoContainer>
            <StyledMenu direction="row-responsive" align="center">
              <Menu
                onClick={handleClick}
                selectedKeys={current}
                mode="horizontal"
                openAnimation="slide-up"
              >
                {menuItems.map((item, index) =>
                  item.children.length > 0 ? (
                    <StyledSubMenu
                      title={
                        <span className="submenu-title-wrapper">
                          {item.label}
                        </span>
                      }
                    >
                      {item.children.map((child, index) =>
                        child.children.length > 0 ? (
                          <StyledSubMenu
                            title={
                              <span className="submenu-title-wrapper">
                                {child.label}
                              </span>
                            }
                          >
                            {child.children.map((child, index) => (
                              <StyledMenuItem key={child.link}>
                                {child.label}
                              </StyledMenuItem>
                            ))}
                          </StyledSubMenu>
                        ) : (
                          <StyledMenuItem key={child.link}>
                            {child.label}
                          </StyledMenuItem>
                        )
                      )}
                    </StyledSubMenu>
                  ) : (
                    <StyledMenuItem key={item.link}>hello</StyledMenuItem>
                  )
                )}
              </Menu>
              <Language post={post} />
            </StyledMenu>
          </AppBar>
        }
      />
    )
  }
}

AppBarMenu.propTypes = {
  data: PropTypes.object,
  post: PropTypes.object,
  menuItems: PropTypes.array,
  current: PropTypes.string,
  handleClick: PropTypes.func,
}

export default AppBarMenu
