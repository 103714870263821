import 'antd/lib/menu/style/index.css'
import 'antd/lib/icon/style/index.css'

import { Box, Grommet, Heading, ResponsiveContext } from 'grommet'
import React, { Component } from 'react'
import { StaticQuery, graphql, navigate } from 'gatsby'
import { injectIntl, intlShape } from 'react-intl'

import AppBarMenu from './appBarMenu'
import Burger from './burger'
import Icon from 'antd/lib/icon'
import Img from 'gatsby-image'
import Language from './language'
import { Link } from '../../i18n'
import Menu from 'antd/lib/menu'
import PropTypes from 'prop-types'
import { fluidImage } from '../../fragments/fragments'
import styled from 'styled-components'

class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
      current: '/',
      renderTimes: 0,
    }

    this.handleClick = this.handleClick.bind(this)
  }

  static contextTypes = {
    language: PropTypes.object,
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      const link = window.location.pathname.slice(4)
      this.setState({
        current:
          link.substr(-1) !== '/'
            ? `${window.location.pathname.slice(4)}/`
            : link,
      })
    }
  }

  handleClick = e => {
    if (e.key.indexOf('http') !== -1) {
      window.open(e.key, '_blank')
      window.focus()
    } else {
      this.setState({
        current: e.key,
      })
      navigate(e.key)
    }
  }
  render() {
    const { menuItems, post = null } = this.props

    return (
      <StaticQuery
        query={graphql`
          query {
            cybermilesLogo: file(relativePath: { eq: "logos/cybermilesLogo.png" }) {
              childImageSharp {
                fixed(width: 169, height: 35) {
                  ...GatsbyImageSharpFixed_tracedSVG
                }
              }
            }
            mobileDrawer: file(relativePath: { eq: "mobileDrawer.png" }) {
              childImageSharp {
                fluid(maxWidth: 36) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        `}
        render={data => (
          <header>
            <ResponsiveContext.Consumer>
              {size => {
                // Prevent rendering for the first time
                // Because of the guessed size by grommet responsiveContext
                if (this.state.renderTimes++ == 0) {
                  return undefined
                }
                if (size === 'large' || size === 'xlarge') {
                  return (
                    <AppBarMenu
                      current={this.state.current}
                      data={data}
                      handleClick={this.handleClick}
                      menuItems={menuItems}
                      post={post}
                    />
                  )
                } else {
                  return (
                    <Burger
                      current={this.state.current}
                      data={data}
                      handleClick={this.handleClick}
                      menuItems={menuItems}
                      post={post}
                    />
                  )
                }
              }}
            </ResponsiveContext.Consumer>
          </header>
        )}
      />
    )
  }
}

export default Header
