import { Box } from 'grommet'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const StyledLinks = styled.a`
  color: ${props =>
    props.light
      ? props.theme.colors.primaryWhite
      : props.theme.colors.primaryDark};
  display: inline-block;
  position: relative;
  text-decoration: none;
  padding: 0 5px;
  &:before {
    background-color: ${props =>
      props.light
        ? props.theme.colors.primaryWhite
        : props.theme.colors.primaryDark};
    content: '';
    height: 2px;
    position: absolute;
    bottom: -1px;
    left: 50%;
    transform: translateX(-50%);
    transition: width 0.3s ease-in-out;
    width: 0;
  }

  &:hover {
    color: ${props =>
      props.theme && props.theme.colors && props.theme.colors.fadedGrey};
    text-decoration: none;
    &:before {
      width: 100%;
    }
  }
`
const StyledLink = ({ link, label, light }) => {
  return light ? (
    <StyledLinks light target="_blank" href={link} >{ label }</StyledLinks>
  ) : (
    <StyledLinks href={link} target="_blank">{label}</StyledLinks>
  )
}

StyledLink.propTypes = {
  link: PropTypes.string,
  label: PropTypes.string,
  light: PropTypes.bool,
}

export default StyledLink
