import styled, { ThemeProvider } from 'styled-components'

import { Box } from 'grommet'
import FooterIcons from './footerIcons'
import Img from 'gatsby-image'
import InputButton from '../reusables/inputButton'
import PropTypes from 'prop-types'
import React from 'react'
import StyledLink from '../reusables/link'
import { theme } from '../../utilities/theme.js'

const StyledP = styled.p`
  font-size: 12px;
  margin: 0;
  text-align: left;
`

const Footer = ({ locale }) => {
  const messages = require(`../../i18n/locales/${locale}/navigation/footer.js`)
  const { address, copyright, iconsTitle, links, subscribe } = messages
  const { privacy, terms, contact } = links
  const { label, title } = subscribe

  return (
    <footer>
      <ThemeProvider theme={theme}>
        <Box pad={{"horizontal": "xlarge", "vertical": "large"}} background="primaryDarker">
          <Box direction="row-responsive" justify="between">
            <Box align="center" justify="center" basis="55%">
              <InputButton label={label} title={title} shade="dark"/>
            </Box>
            <Box align="center" justify="start">
              <FooterIcons iconsTitle={iconsTitle} />
            </Box>
          </Box>
          <Box margin={{"top": "100px"}} direction="row-responsive" justify="between">
            <Box align="start" justify="center" basis="55%">
              <StyledP>{address}</StyledP>
              <StyledP>{copyright}</StyledP>
            </Box>
            <Box direction="row" align="start" justify="start">
              <StyledP>
                <StyledLink
                  light={true}
                  link={contact.link}
                  label={contact.label}
                />
              </StyledP>
              <StyledP>
                <StyledLink
                  light={true}
                  link={privacy.link}
                  label={privacy.label}
                />
              </StyledP>
              <StyledP>
                <StyledLink
                  light={true}
                  link={terms.link}
                  label={terms.label}
                />
              </StyledP>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </footer>
  )
}

Footer.propTypes = {
  locale: PropTypes.string,
}

export default Footer
