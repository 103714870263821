import 'lightbox-react/style.css'

import { Anchor, Box } from 'grommet'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import React, { Component } from 'react'
import { StaticQuery, graphql, navigate } from 'gatsby'

import Img from 'gatsby-image'
import Lightbox from 'lightbox-react'
import PropTypes from 'prop-types'
import { footer } from '../../fragments/fragments'
import shortid from 'shortid'
import styled from 'styled-components'
import wechatQR from '../../assets/images/wechatQR.jpg'

const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  text-align: center;
`

const StyledLink = styled.a`
  height: 35px;
  width: 35px;
  margin: 0.7em;

  &:hover {
    opacity: 0.5;
  }

  picture img {
    height: 30px !important;
    width: 30px !important;
    object-fit: contain !important;
  }
`

const PopupLink = styled(StyledLink)``

const StyledHeader = styled.h3`
  font-weight: 300;
`

const StyledImage = styled(Img)``

const socialIcons = [
  {
    label: 'twitter_footer',
    link: 'https://twitter.com/cybermiles',
  },
  {
    label: 'facebook_footer',
    link: 'https://www.facebook.com/cybermiles',
  },
  {
    label: 'weibo_footer',
    link: 'https://weibo.com/Cybermiles',
  },
  {
    label: 'telegram_footer',
    link: 'https://t.me/cybermilestoken',
  },
  {
    label: 'wechat_footer',
    link: 'https://twitter.com/cybermiles',
  },
  {
    label: 'kakao_footer',
    link: 'https://open.kakao.com/o/gl9gPJI',
  },
  // {
  //   label: "slack_footer",
  //   link: "https://twitter.com/cybermiles",
  // },
  {
    label: 'github_footer',
    link: 'https://github.com/cybermiles',
  },
  // {
  //   label: "reddit_footer",
  //   link: "https://twitter.com/cybermiles",
  // },
  // {
  //   label: "youtube_footer",
  //   link: "https://twitter.com/cybermiles",
  // },
  // {
  //   label: "toutiao_footer",
  //   link: "https://twitter.com/cybermiles",
  // },
  // {
  //   label: "linkedin_footer",
  //   link: "https://twitter.com/cybermiles",
  // },
  // {
  //   label: "medium_footer",
  //   link: "https://twitter.com/cybermiles",
  // },
]

class FooterIcons extends Component {
  constructor(props) {
    super(props)

    this.state = {
      photoIndex: 0,
      isOpen: false,
    }
  }
  render() {
    const { photoIndex, isOpen } = this.state
    return (
      <StaticQuery
        query={graphql`
          query {
            twitter_footer: file(
              relativePath: { eq: "icons/twitter-footer.png" }
            ) {
              ...footer
            }
            facebook_footer: file(
              relativePath: { eq: "icons/facebook-footer.png" }
            ) {
              ...footer
            }
            telegram_footer: file(
              relativePath: { eq: "icons/telegram-footer.png" }
            ) {
              ...footer
            }
            wechat_footer: file(
              relativePath: { eq: "icons/wechat-footer.png" }
            ) {
              ...footer
            }
            github_footer: file(
              relativePath: { eq: "icons/github-footer.png" }
            ) {
              ...footer
            }
            weibo_footer: file(relativePath: { eq: "icons/weibo-footer.png" }) {
              ...footer
            }
            kakao_footer: file(relativePath: { eq: "icons/kakao-footer.png" }) {
              ...footer
            }
          }
        `}
        render={data => (
          <Box>
            <StyledHeader>{this.props.iconsTitle}</StyledHeader>
            <Wrapper>
              {socialIcons.map((icon, index) => {
                const iconPath = data[`${icon.label}`].childImageSharp.fluid
                return icon.label === 'wechat_footer' ? (
                  <StyledLink
                    key={shortid.generate()}
                    onClick={() => this.setState({ isOpen: true })}
                  >
                    <Img alt={icon.label} fluid={iconPath} />
                  </StyledLink>
                ) : (
                  <StyledLink
                    target="_blank"
                    href={icon.link}
                    key={shortid.generate()}
                  >
                    <Img alt={icon.label} fluid={iconPath} />
                  </StyledLink>
                )
              })}
            </Wrapper>
            {isOpen && (
              <Lightbox
                mainSrc={wechatQR}
                onCloseRequest={() => this.setState({ isOpen: false })}
              />
            )}
          </Box>
        )}
      />
    )
  }
}

export default FooterIcons
