import { sizes, theme } from '../utilities/theme.js'
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components'

import CssBaseline from '@material-ui/core/CssBaseline'
import Footer from './navigation/footer'
import { Grommet } from 'grommet'
import Header from './navigation/header'
import Helmet from 'react-helmet'
import React from 'react'
import SEO from './seo'
import burgerIcon from '../assets/images/favicon-cybermiles.png'
import { deepMerge } from 'grommet/utils'
import { grommet } from 'grommet/themes'
import { injectIntl } from 'react-intl'

const { colors } = theme

const customBreakpoints = deepMerge(grommet, {
  global: {
    colors: colors,
    breakpoints: sizes,
    font: {
      family: 'Montserrat',
    },
    edgeSize: {
      large: '64px',
      xlarge: '100px',
    },
    h1: {
      fontSize: '44px',
      lineHeight: 'normal',
      fontWeight: 'normal',
    },
    h2: {
      fontSize: '32px',
      lineHeight: 'normal',
      fontWeight: 'normal',
    },
    h3: {
      fontSize: '22px',
      lineHeight: 'normal',
      fontWeight: 'normal',
    },
    p: {
      fontSize: '18px',
      lineHeight: '32px',
      fontWeight: 'normal',
    },
  },
})

const Container = styled.div``

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Montserrat', -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol" !important;
    h1 {
      font-size: 44px;
      line-height: normal;
      font-weight: normal;
    }
    h2 {
      font-size: 32px;
      line-height: normal;
      font-weight: normal;
    }
    h3 {
      font-size: 22px;
      line-height: normal;
      font-weight: normal;
    }
    p {
      font-size: 18px;
      line-height: 1.5;
      font-weight: normal;
    }
  }
`

const Layout = ({ children, data, intl }) => {
  const script = intl.messages.script || null
  const locale = intl.locale
  const messages = require(`../i18n/locales/${locale}/navigation/header.js`)
  const menuItems = messages.default.items

  return (
    <Container>
      <SEO
        title={intl.formatMessage({ id: 'title' })}
        description={intl.formatMessage({ id: 'meta-description' })}
      />
      {/* <Helmet
        title={intl.formatMessage({ id: 'title' })}
        meta={[
          {
            name: 'description',
            content: intl.formatMessage({ id: 'meta-description' }),
          },
          {
            name: 'keywords',
            content: 'gatsby, i18n, react-intl, multi language, localization',
          },
        ]}
        link={[
          { rel: 'shortcut icon', type: 'image/png', href: `${burgerIcon}` },
        ]}
      /> */}

      <Grommet theme={customBreakpoints}>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <div>
            <GlobalStyle />
            <Header menuItems={menuItems} />
            {children}
            <Footer locale={locale} />
          </div>
        </ThemeProvider>
      </Grommet>
    </Container>
  )
}

export default injectIntl(Layout)
