import 'antd/lib/menu/style/index.css'
import 'antd/lib/icon/style/index.css'

import React, { Component } from 'react'
import { StaticQuery, graphql, navigate } from 'gatsby'

import Banner from '../reusables/banner'
import { Box } from 'grommet'
import BurgerLanguage from './burgerLanguage'
import { stack as BurgerMenu } from 'react-burger-menu'
import Icon from 'antd/lib/icon'
import Img from 'gatsby-image'
import Menu from 'antd/lib/menu'
import styled from 'styled-components'

const SubMenu = Menu.SubMenu
const MenuItemGroup = Menu.ItemGroup

const BurgerWrapper = styled.div`
  .ant-menu {
    background: transparent;
    border-right: 1px solid transparent;
    color: ${props => props.theme.colors.primaryWhite};

    .ant-menu-sub {
      color: ${props => props.theme.colors.primaryGrey};
    }

    .ant-menu-submenu {
      .ant-menu {
        background-color: transparent;

        .ant-menu-item-group-title {
          color: ${props => props.theme.colors.primaryWhite};
        }
      }
      
    }
  }

  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #373a47;
    // padding: 2.5em 1.5em 0;
    padding: 2.5em 0;
    font-size: 1.15em;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    // padding: 0.8em;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
`

const StyledLogoContainer = styled.a`
  margin: 20px;
`

class Burger extends React.Component {
  state = {
    openKeys: [''],
  }

  onOpenChange = openKeys => {
    const latestOpenKey = openKeys.find(
      key => this.state.openKeys.indexOf(key) === -1
    )
    if (this.props.menuItems.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys })
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      })
    }
  }

  render() {
    const { post, menuItems, current, handleClick, data } = this.props

    return (
      <BurgerWrapper>
        <BurgerMenu customBurgerIcon={<Img alt="mobile drawer" fluid={data.mobileDrawer.childImageSharp.fluid} />}>
          <Menu
            mode="inline"
            openKeys={this.state.openKeys}
            onOpenChange={this.onOpenChange}
            onClick={handleClick}
            selectedKeys={[current]}
            style={{ width: '100%' }}
          >
            <StyledLogoContainer href="/">
              <Img alt="cybermiles logo" fixed={data.cybermilesLogo.childImageSharp.fixed} />
            </StyledLogoContainer>
            {menuItems.map((item, index) =>
              item.children.length > 0 ? (
                <SubMenu
                  key={item.label}
                  title={
                    <span className="submenu-title-wrapper">
                      {/* <Icon type="setting" /> */}
                      {item.label}
                    </span>
                  }
                >
                  {item.children.map((child, index) =>
                    child.children.length > 0 ? (
                      <MenuItemGroup key={index} title={child.label}>
                        {child.children.map((child, index) => (
                          <Menu.Item
                            disabled={child.disabled === 'true'}
                            key={child.link}
                          >
                            {child.label}
                          </Menu.Item>
                        ))}
                      </MenuItemGroup>
                    ) : (
                      <Menu.Item
                        disabled={child.disabled === 'true'}
                        key={child.link}
                      >
                        {child.label}
                      </Menu.Item>
                    )
                  )}
                </SubMenu>
              ) : (
                <Menu.Item disabled={child.disabled === 'true'} key={item.link}>
                  {item.label}
                </Menu.Item>
              )
            )}
            <BurgerLanguage post={post} />
          </Menu>
        </BurgerMenu>
      </BurgerWrapper>
    )
  }
}

export default Burger
