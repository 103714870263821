import { Link, withIntl } from '../../i18n'
import { StaticQuery, graphql } from 'gatsby'
import { bannerIcon, fluidImage } from '../../fragments/fragments'

import Banner from '../../components/reusables/banner'
import Layout from '../../components/layout'
import React from 'react'
import Timeline from '../../components/reusables/timeline'

const Roadmap = ({ data }) => (
  <Layout>
    <Banner data={data} shade={'light'} />
    <Timeline data={data} shade={'light'}/>
  </Layout>
)

export default withIntl(Roadmap)

export const imagesQuery = graphql`
  query {
    bannerGlobe: file(relativePath: { eq: "hero/bannerGlobe@3x.png" }) {
      ...bannerIcon
    }
    lastSection: file(relativePath: { eq: "hero/lastSection@2x.png" }) {
      childImageSharp {
        fluid(maxHeight: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`