import { css } from 'styled-components'

export const sizes = {
  xlarge: {
    value: 3000,
  },
  large: {
    value: 1200,
  },
  medium: {
    value: 1024,
  },
  small: {
    value: 768,
  },
  xsmall: {
    value: 640,
  }
}

// iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((accumulator, label) => {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const emSize = sizes[label].value / 16
  accumulator[label] = (...args) => css`
    @media (max-width: ${emSize}em) {
      ${css(...args)};
    }
  `
  return accumulator
}, {})

export const theme = {
  colors: {
    primary: '#3498db',
    primaryDark: '#3c5785',
    primaryDarker: '#26344c',
    primaryWhite: '#FFFFFF',
    primaryGrey: '#edeef2',
    fadedGrey: '#67656f',
    greyBorder: '#e7e7e7',
    buttonDark: '#3c5785',
    buttonDarkHover: '#30456a',
    buttonLight: '#FFFFFF',
    buttonLightHover: '#a6a6a6',
    menuItem: '#434353',
    primaryBlack: '#0b0c17',
    weakGrey: '#cccccc',
  },
  fontSize: {
    xl: "2.4rem",
    lg: "1.8rem",   
    md: "1.3rem",
    nm: "1rem",
    sm: "0.75rem"
  },
}