import { Box, Button } from 'grommet'
import React, { Component } from 'react'

import Img from 'gatsby-image'
import Lightbox from 'lightbox-react'
import media from 'styled-media-query'
import styled from 'styled-components'
import wechatQR from '../../assets/images/wechatQR.jpg'

const StyledButton = styled(Button)`
  ${media.greaterThan('small')`    
    display: ${props => (props.href === "itms-services://?action=download-manifest&url=https://s3.cn-north-1.amazonaws.com.cn/cmtwallet/ios/manifest.plist" || props.href === "https://s3.cn-north-1.amazonaws.com.cn/cmtwallet/android/CMT-Wallet-Android.apk") ? "none" : "inline-block"} !important;
  `}
  font-size: 16px;
  font-weight: normal;
  padding: 8px 12px;
  border-radius: 4px 4px 4px 4px;
  transition: all 500ms ease;
  text-transform: uppercase;

  color: ${props => props.theme.colors.buttonLight};
  box-shadow: inset 0 0 0 1px
    ${props =>
      props.light
        ? props.theme.colors.buttonLight
        : props.theme.colors.buttonDark};
  background: ${props =>
    props.light ? 'transparent' : props.theme.colors.buttonDark};
  border: none;

  &:hover {
    background: ${props =>
      props.light ? 'transparent' : props.theme.colors.buttonDarkHover};
    box-shadow: 0 0 1px 0
      ${props =>
        props.light
          ? props.theme.colors.buttonLightHover
          : props.theme.colors.buttonDarkHover};
    color: ${props =>
      props.light
        ? props.theme.colors.buttonLightHover
        : props.theme.colors.buttonLight};
    background-origin: border-box;
  }

  ${media.greaterThan('small')`
  padding: 15px 48px;
`}
`
const StyledSocialIconButton = styled(StyledButton)`
  padding: 12px 2px;
  min-width: 190px;

  ${media.greaterThan('small')`
    padding: 12px 2px;
  `}
`

const StyledImg = styled(Img)`
  height: 32px;
  width: 32px;
`

class GrommetButton extends Component {
  constructor(props) {
    super(props)

    this.state = {
      photoIndex: 0,
      isOpen: false,
    }
  }
  render() {
    const { label, link, onClick, light, icon = false, data } = this.props
    const iconPath = icon ? data[`${icon}`].childImageSharp.fixed : false
    const { isOpen } = this.state
    return icon ? (
      link ? (
        label === 'wechat' ? (
          <Box>
            <StyledSocialIconButton
              label={label}
              onClick={() => this.setState({ isOpen: true })}
              icon={
                <StyledImg
                  imgStyle={{ objectFit: 'contain' }}
                  fixed={iconPath}
                />
              }
            />
            {isOpen && (
              <Lightbox
                mainSrc={wechatQR}
                onCloseRequest={() => this.setState({ isOpen: false })}
              />
            )}
          </Box>
        ) : (
          <StyledSocialIconButton
            label={label}
            href={link}
            target="_blank"
            icon={
              <StyledImg imgStyle={{ objectFit: 'contain' }} fixed={iconPath} />
            }
          />
        )
      ) : (
        <StyledButton
          label={label}
          onClick={onClick}
          icon={
            <StyledImg imgStyle={{ objectFit: 'contain' }} fixed={iconPath} />
          }
        />
      )
    ) : link ? (
      light ? (
        <StyledButton label={label} href={link} target="_blank" />
      ) : (
        <StyledButton light label={label} href={link} target="_blank" />
      )
    ) : light ? (
      <StyledButton label={label} onClick={onClick} />
    ) : (
      <StyledButton light label={label} onClick={onClick} />
    )
  }
}

export default GrommetButton
