import 'rc-menu/assets/index.css'

import Menu, { Divider, Item as MenuItem, SubMenu } from 'rc-menu'
import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { ResponsiveContext } from 'grommet'
import { languages } from '../../i18n/locales'
import styled from 'styled-components'

const StyledSubMenu = styled(SubMenu)`
  font-size: 14px;
  color: ${props => props.theme.colors.menuItem};
  font-family: Montserrat;

  .submenu-title-wrapper {
    border: 1px solid ${props => props.theme.colors.primaryDark};
    padding: 8px 30px;
  }
  
`

const StyledMenuItem = styled(MenuItem)`
  font-size: 14px;
  color: ${props => props.theme.colors.menuItem};
  font-family: Montserrat;
`
class Language extends Component {
  state = {
    value: '',
    path: '',
  }

  static contextTypes = {
    language: PropTypes.object,
  }

  componentDidMount() {
    if (this.props.post) {
      const { post } = this.props
      const { lang } = post
      this.setState({
        value: lang || 'en',
      })
    } else {
      const { language } = this.context
      this.setState({
        value: language.locale || language.detected,
      })
    }
  }

  handleChange = e => {
    const value = e.key
    let path = ''

    if (value === this.state.value) {
      return
    }

    if (this.props.post) {
      const { post } = this.props
      const { alternate_languages: languages } = post
      const locale = languages.find(language => language.lang === value)
      path = `/${value}/blog/${locale.uid}`
    } else {
      const { language } = this.context
      const { originalPath } = language
      path = `/${value}${originalPath}`
    }

    this.setState({ value, path }, () => {
      localStorage.setItem('language', value)
      window.location.href = path
    })
  }

  render() {
    const currentValue = this.state.value

    if (!currentValue) {
      return null
    }

    return (
      <ResponsiveContext.Consumer>
        {size => (
          <Menu
            onClick={this.handleChange}
            selectedKeys={[this.state.value]}
            mode={
              size === 'large' || size === 'xlarge' ? 'horizontal' : 'inline'
            }
            openAnimation="slide-up"
          >
            <StyledSubMenu
              title={
                <span className="submenu-title-wrapper">
                  {
                    languages.find(language => language.value === currentValue)
                      .text
                  }
                </span>
              }
            >
              {languages.map(({ value, text }) => (
                <StyledMenuItem key={value}>{text}</StyledMenuItem>
              ))}
            </StyledSubMenu>
          </Menu>
        )}
      </ResponsiveContext.Consumer>
    )
  }
}

Language.propTypes = {
  post: PropTypes.object,
}

export default Language
