import 'antd/lib/timeline/style/index.css'

import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import React, { Component } from 'react'
import {
  StyledContainer,
  StyledH3,
  StyledP,
  StyledRowButtonContainer,
  StyledSpan,
  StyledUnderlinedH2
} from './styles'

import { Box } from 'grommet'
import Button from '../reusables/button'
import PropTypes from 'prop-types'
import Timeline from 'antd/lib/timeline'
import media from 'styled-media-query'
import shortid from 'shortid'
import styled from 'styled-components'

const StyledEvent = styled(Box)`
  ${media.greaterThan('medium')`
    border: 1px solid ${props => props.theme.global.colors.weakGrey};
    padding: 24px;
  `}
`

const StyledDate = styled(StyledH3)`
  color: ${props => props.theme.global.colors.primaryBlack};
  text-transform: none;
  font-weight: 500;
`

const ProdList = styled.ul`
  padding: 1em 0 0;
`

const ProdListItem = styled.li`
  list-style: disc;
  list-style-position: inside;
`

const StyledInnerContainer = styled(Timeline)`
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol' !important;

  .ant-timeline-item-tail {
    height: 100%;
    bottom: 0 !important;
    top: auto !important;
  }

  ${media.greaterThan('medium')`
    .ant-timeline-item-left {
      .ant-timeline-item-content {
        margin-left: 80px !important;
        max-width: 40% !important;
      }
    }
  
    .ant-timeline-item-right {
      .ant-timeline-item-content {
        left: 0 !important;
        max-width: 40% !important;
        text-align: left !important;
      }
    }
  
    .ant-timeline-item-head {
      margin-left: -7px !important;
      width: 16px;
      height: 16px;
    }
  `}
`

const HeadItem = styled(Timeline.Item)`
  .ant-timeline-item-head {
    margin-left: 0px !important;
    width: 40px;
    height: 40px;
    padding-top: 12px;
    font-size: 1em;
    font-weight: bold;
  }
  .ant-timeline-item-head-custom {
    border: 2px solid #1890ff !important;
    border-radius: 50% !important;
  }

  ${media.greaterThan('medium')`
    .ant-timeline-item-head {
      width: 60px;
      height: 60px;
      padding-top: 18px;
      font-size: 1.4em;
      font-weight: bold;
    }
  `}
`

const StyledTimeline = ({ data, shade, intl }) => {
  const title = intl.formatMessage({
    id: `roadmap.title`,
  })
  const hasTitle = title !== 'null'
  const description = intl.formatMessage({
    id: `roadmap.description.description`,
  })
  const hasDescription = description !== 'null'
  const isRichText =
    intl.formatMessage({
      id: `roadmap.description.richText`,
    }) !== 'null'
  const events = intl.formatMessage({
    id: `roadmap.events.number`,
  })
  const buttons = intl.formatMessage({
    id: `roadmap.buttons.number`,
  })
  const backgroundImage = intl.formatMessage({
    id: `roadmap.backgroundImage`,
  })
  return (
    <StyledContainer
      componentName="Timeline"
      shade={shade}
      data={data}
      backgroundImage={backgroundImage}
    >
      {hasTitle && (
        <StyledUnderlinedH2 shade={shade}>{title}</StyledUnderlinedH2>
      )}
      {hasDescription && !isRichText ? (
        <StyledSpan>{description}</StyledSpan>
      ) : (
        hasDescription &&
        isRichText && (
          <StyledSpan
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )
      )}
      <StyledInnerContainer mode="alternate">
        {Array.from(Array(Number(events)).keys()).map((event, index) => {
          const title = intl.formatMessage({
            id: `roadmap.events.event${index}.title`,
          })
          const outline = intl.formatMessage({
            id: `roadmap.events.event${index}.outline`,
          })
          const parent = intl.formatMessage({
            id: `roadmap.events.event${index}.parent`,
          })
          const list = intl.formatMessage({
            id: `roadmap.events.event${index}.list.size`,
          })
          if (parent === 'true') {
            return (
              <HeadItem
                key={shortid.generate()}
                dot={title}
              >
                <StyledEvent>
                  <StyledP style={{ margin: '0 0 0 0' }}>
                    {outline}
                  </StyledP>
                </StyledEvent>
              </HeadItem>
            )
          } else {
            return (
              <Timeline.Item
                key={shortid.generate()}
              >
                <StyledEvent>
                  <StyledDate style={{ margin: '0' }}>{title}</StyledDate>
                  <ProdList>
                    {Array.from(Array(Number(list)).keys()).map((_, li) => {
                      const item = intl.formatMessage({
                        id: `roadmap.events.event${index}.list.item${li}`
                      })
                      return (
                        <ProdListItem>{item}</ProdListItem>
                      )
                    })}
                  </ProdList>
                </StyledEvent>
              </Timeline.Item>
            )
          }
        })}
      </StyledInnerContainer>
      {buttons > 0 && (
        <StyledRowButtonContainer buttons={buttons}>
          {Array.from(Array(Number(buttons)).keys()).map(i => {
            const button = `button${i}`
            const buttonLabel = intl.formatMessage({
              id: `roadmap.buttons.${button}.label`,
            })
            const buttonLink = intl.formatMessage({
              id: `roadmap.buttons.${button}.link`,
            })
            return (
              <Button
                key={shortid.generate()}
                label={buttonLabel}
                link={buttonLink}
                light={shade === 'light' || shade === 'grey' ? true : false}
              />
            )
          })}
        </StyledRowButtonContainer>
      )}
    </StyledContainer>
  )
}

StyledTimeline.propTypes = {
  shade: PropTypes.string,
  data: PropTypes.object,
  intl: intlShape.isRequired,
}

export default injectIntl(StyledTimeline)
