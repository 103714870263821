import { Box } from 'grommet'
import Img from 'gatsby-image'
import React from 'react'
import media from 'styled-media-query'
import styled from 'styled-components'

const Container = styled(Box)``

const ContainerWithBackground = styled(Box)`
  width: 100%;
  height: 100%;
  position: relative;
`

export const StyledContainer = props => {
  const {
    children,
    componentName,
    shade,
    backgroundImage = 'null',
    data,
  } = props
  const hasBackground = backgroundImage !== 'null'
  let backgroundPath = 'shortLastSection'
  if (hasBackground) {
    backgroundPath = data[`${backgroundImage}`].childImageSharp.fluid
  }
  return hasBackground ? (
    <ContainerWithBackground
      a11yTitle={componentName}
      background={
        shade === 'light'
          ? 'primaryWhite'
          : shade === 'grey'
          ? 'primaryGrey'
          : 'primaryDark'
      }
      {...props}
    >
      <Img
        alt={backgroundImage}
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          objectFit: 'cover',
          zIndex: '0',
        }}
        fluid={backgroundPath}
      />
      <Box
        align="center"
        justify="center"
        height="100%"
        pad={{ horizontal: 'xlarge', vertical: 'large' }}
        style={{
          width: '100%',
          position: 'relative',
          zIndex: '0',
        }}
      >
        {children}
      </Box>
    </ContainerWithBackground>
  ) : (
    <Container
      a11yTitle={componentName}
      pad={{ horizontal: 'xlarge', vertical: 'large' }}
      align="center"
      justify="center"
      background={
        shade === 'light'
          ? 'primaryWhite'
          : shade === 'grey'
          ? 'primaryGrey'
          : 'primaryDark'
      }
      {...props}
    />
  )
}

const H2 = styled.h2`
  color: ${props => props.theme.global.colors.primaryDark};
  position: relative;
  margin-bottom: 24px;
`
export const StyledH2 = props => <H2 {...props} />

const UnderlinedH2 = styled(H2)`
  margin-bottom: 72px;
  &::after {
    display: block;
    content: '';
    width: 100px;
    height: 1px;
    background: grey;
    position: absolute;
    bottom: -24px;
    left: 0;
    margin-top: 10px;
    opacity: 0.5;
    ${media.greaterThan('medium')`
      left: 50%;
      transform: translate(-50%, 0);
      align-self: center;
    `}
  }
  align-self: start;
  ${media.greaterThan('small')`
    align-self: center;
  `}
`

export const StyledUnderlinedH2 = props => <UnderlinedH2 {...props} />

const H3 = styled.h3`
  color: ${props => props.theme.global.colors.primaryDark};
  text-transform: uppercase;
`

export const StyledH3 = props => <H3 {...props} />

const P = styled.p`
  color: ${props => props.theme.global.colors.fadedGrey};
  a {
    color: ${props => props.theme.global.colors.fadedGrey};
    display: inline-block;
    position: relative;
    text-decoration: none;
    padding: 0 2px;
    &:before {
      background-color: ${props => props.theme.global.colors.primaryDark};
      content: '';
      height: 2px;
      position: absolute;
      bottom: -1px;
      left: 50%;
      transform: translateX(-50%);
      transition: width 0.3s ease-in-out;
      width: 0;
    }

    &:hover {
      color: ${props => props.theme.global.colors.primaryDark};
      text-decoration: none;
      &:before {
        width: 100%;
      }
    }
  }
`

export const StyledP = props => <P {...props} />

const Span = styled.span`
  color: ${props => props.theme.global.colors.fadedGrey};
  p {
    color: ${props => props.theme.global.colors.fadedGrey};
    a {
      color: ${props => props.theme.global.colors.fadedGrey};
      display: inline-block;
      position: relative;
      text-decoration: none;
      padding: 0 2px;
      &:before {
        background-color: ${props => props.theme.global.colors.primaryDark};
        content: '';
        height: 2px;
        position: absolute;
        bottom: -1px;
        left: 50%;
        transform: translateX(-50%);
        transition: width 0.3s ease-in-out;
        width: 0;
      }

      &:hover {
        color: ${props => props.theme.global.colors.primaryDark};
        text-decoration: none;
        &:before {
          width: 100%;
        }
      }
    }
  }
`

export const StyledSpan = props => <Span {...props} />

const ColumnsContainer = styled(Box)`
  display: flex;
  flex-flow: row wrap;
  margin: 0 -20px;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
`

export const StyledColumnsContainer = props => <ColumnsContainer {...props} />

const ColumnContainer = styled(Box)`
  width: ${props => props.width};
  padding: 10px 10px;
  display: grid;
  justify-items: center;
`
export const StyledColumnContainer = props => <ColumnContainer {...props} />

const ColumnButtonContainer = styled(Box)`
  display: grid;
  align-items: start;
  grid-template-rows: repeat(${props => props.buttons}, 1fr);
  grid-row-gap: 20px;
  margin-top: 40px;
  margin-bottom: 20px;
  width: 100%;
  ${media.greaterThan('small')`
    grid-template-columns: repeat(${props => props.buttons}, 1fr);
    grid-column-gap: 20px;
    width: auto;
  `}
`
export const StyledColumnButtonContainer = props => (
  <ColumnButtonContainer {...props} />
)

const RowButtonContainer = styled(StyledColumnButtonContainer)`
  grid-template-columns: repeat(${props => props.buttons}, 1fr);
  grid-column-gap: 20px;
`

export const StyledRowButtonContainer = props => (
  <RowButtonContainer {...props} />
)

const FlexColumnsContainer = styled(Box)`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`

export const StyledFlexColumnsContainer = props => (
  <FlexColumnsContainer {...props} />
)

const FlexColumnContainer = styled(Box)`
  margin: 20px;
`
export const StyledFlexColumnContainer = props => (
  <FlexColumnContainer {...props} />
)


  // grid-gap: 2%;
  // grid-template-columns: 100%;
  // align-items: center;
  // grid-gap: 20px;
  // justify-items: center;
  // align-items: start;

  // ${media.greaterThan('small')`
  //   display: grid;
  //   grid-template-columns: repeat(${props =>
  //     props.columns >= 2 ? props.columns - 1 : 2}, 1fr);
  //     width: 100%;
  //     justify-content: space-evenly;
  // `}

  // ${media.greaterThan('large')`
  //     grid-template-columns: repeat(${props => props.columns}, 1fr);
  //   `}

    // ${media.lessThan('small')`
  //   margin-bottom: 20px;
  // `}

  // display: grid;
  // justify-items: center;
  // text-align: center;